import utils from '@/utils/utils';

export default {
  data() {
    return {
      editing: false,
    };
  },
  methods: {
    validateRequired(tab, field, value) {
      this.steps[tab].required[field] = !(this.steps[tab].rules[field](value) === true);
      utils.revalidate(this);
    },
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },
};
