<template>
  <FormContent :selected="selected" class="pa-6" background-color="transparent">
    <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab href="#tab1">CARTÕES BANDEIRADOS</v-tab>
        <v-tab href="#tab2">CARTÃO KOERICH</v-tab>
        <v-tab href="#tab3">DESCONTOS</v-tab>
      </v-tabs>
    </v-col>
    <v-tabs-items v-model="tab" class="background-default">
      <v-tab-item v-if="form.installmentConfig" value="tab1" class="pa-6" style="background-color: #ffffff00">
        <div class="d-flex mb-10">
          <label class="mr-5 left-content">Valor mínimo parcelamento{{ form.installmentConfig.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="mr-5 right-content"
            v-model="form.installmentConfig.minValue"
            :options="MaskOptions"
            dense
            filled
            :rules="[Rules.required]"
            @focusout="validate('installmentConfigMinValue')"
          />
          <label class="mr-5 left-content">Nº máximo de parcelas</label>
          <v-select
            class="right-content"
            v-model="form.installmentConfig.maxInstallments"
            placeholder="Selecione a quantidade"
            :items="itens"
            @input="changeNumberInstallments($event, 'installmentConfig', 'cardList')"
            dense
            filled
            clearable
          ></v-select>
        </div>
        <div class="d-flex">
          <label class="mr-5 left-content">Data inicial{{ form.installmentConfig.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="mr-5 right-content"
            v-model="form.installmentConfig.initialDate"
            :options="DateMaskOptions"
            dense
            filled
            placeholder="Informe a data"
            :rules="[Rules.dateFullyFilled, Rules.required, Rules.dateInitial]"
            @focusout="validate('installmentConfigInitialDate')"
          />
          <label class="mr-5 left-content">Data final{{ form.installmentConfig.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="right-content"
            v-model="form.installmentConfig.finalDate"
            :options="DateMaskOptions"
            dense
            filled
            placeholder="Informe a data"
            :rules="[Rules.dateFullyFilled, Rules.required, Rules.dateFinal]"
            @focusout="validate('installmentConfigFinalDate')"
          />
        </div>

        <div v-if="form.installmentConfig.maxInstallments" class="pb-0">
          <h4 class="subtext subtext_form_devide_h4 mb-0">Taxa de juros e coeficientes do parcelamento*</h4>
          <div class="pb-0">
            <DataCrud
              v-if="form.installmentConfig.maxInstallments"
              :table-header="header"
              :columnDecorators='decorators'
              :tableData="cardList.list"
              :enableEditFunction='false'
              :justify-center="false"
              :enableAddFunction='false'
              :showFormDialog="true"
              @changeFormatterInput="changeInterestRates($event, form.installmentConfig)"
              @changePage="changePage(form.installmentConfig.installments, 'cardList', $event)"
              @changeItemsPerPage="changeItemsPerPage(form.installmentConfig.installments, 'cardList', $event)"
              :server-items-length="form.installmentConfig.installments.length"
            >
            </DataCrud>
          </div>
        </div>
        <h6 v-else class="subtext subtext_form_devide_h6" style="margin-top: 1rem">
          É necessário primeiramente selecionar o número máximo de parcelas
        </h6>
        <div class="pb-0">
          <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="reset">
            Redefinir condições
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item v-if="form.installmentConfigKoerich" value="tab2" class="pa-6" style="background-color: #ffffff00">
        <div class="d-flex mb-10">
          <label class="mr-5 left-content">Valor mínimo parcelamento{{ form.installmentConfigKoerich.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="mr-5 right-content"
            v-model="form.installmentConfigKoerich.minValue"
            :options="MaskOptions"
            dense
            filled
            :rules="[Rules.requiredKoerich]"
            @focusout="validate('installmentConfigKoerichMinValue')"
          />
          <label class="mr-5 left-content">Nº máximo de parcelas</label>
          <v-select
            class="right-content"
            v-model="form.installmentConfigKoerich.maxInstallments"
            placeholder="Selecione a quantidade"
            :items="itens"
            @input="changeNumberInstallments($event, 'installmentConfigKoerich', 'koerichList')"
            dense
            filled
            clearable
          ></v-select>
        </div>
        <div class="d-flex">
          <label class="mr-5 left-content">Data inicial{{ form.installmentConfigKoerich.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="mr-5 right-content"
            v-model="form.installmentConfigKoerich.initialDate"
            :options="DateMaskOptions"
            dense
            filled
            placeholder="Informe a data"
            :rules="[Rules.dateFullyFilled, Rules.requiredKoerich, Rules.dateInitialKoerich]"
            @focusout="validate('installmentConfigKoerichInitialDate')"
          />
          <label class="mr-5 left-content">Data final{{ form.installmentConfigKoerich.maxInstallments ? '*' : '' }}</label>
          <mask-input
            class="right-content"
            v-model="form.installmentConfigKoerich.finalDate"
            :options="DateMaskOptions"
            dense
            filled
            placeholder="Informe a data"
            :rules="[Rules.dateFullyFilled, Rules.requiredKoerich, Rules.dateFinalKoerich]"
            @focusout="validate('installmentConfigKoerichFinalDate')"
          />
        </div>
        <v-row class="margin-fix pa-3 pb-3" style="white-space: nowrap">
          <v-col v-if="form.installmentConfigKoerich.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <h4 class="subtext subtext_form_devide_h4 mb-0">Taxa de juros e coeficientes do parcelamento*</h4>
          </v-col>
          <v-col v-if="form.installmentConfigKoerich.maxInstallments" xl="12" lg="12" sm="12" md="12" class="pb-0">
            <DataCrud
              :columnDecorators='decorators'
              :table-header="header"
              :tableData="koerichList.list"
              :enableEditFunction='false'
              :justify-center="false"
              :enableAddFunction='false'
              :enableRemoveFunction='false'
              :showFormDialog="true"
              @changeFormatterInput="changeInterestRates($event, form.installmentConfigKoerich)"
              @changePage="changePage(form.installmentConfigKoerich.installments, 'koerichList', $event)"
              @changeItemsPerPage="changeItemsPerPage(form.installmentConfigKoerich.installments, 'koerichList', $event)"
              :server-items-length="form.installmentConfigKoerich.installments.length"
            >
            </DataCrud>
          </v-col>

          <h6 v-else class="subtext subtext_form_devide_h6" style="margin-top: 1rem">
            É necessário primeiramente selecionar o número máximo de parcelas
          </h6>

          <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
            <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="reset">
              Redefinir condições
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="tab3" class="pa-6" style="background-color: #ffffff00">
        <v-btn color="#07BEE7" style="color: #FFFFFF; float: right" depressed @click="add()">
          + DESCONTO
        </v-btn>
        <DataCrud
          v-if="form.discountsByPaymentType.length > 0"
          :table-header="headerDiscounts"
          :tableData="form.discountsByPaymentType"
          :enableEditFunction='true'
          :justify-center="false"
          :enableAddFunction='false'
          :enableRemoveFunction='true'
          :showFormDialog="true"
          @editEvent="edit"
          @formDelete="remove"
          :column-decorators="decoratorsDiscounts"
          :server-items-length="form.discountsByPaymentType.length"
        >
        </DataCrud>
        <div v-else>
          <span style="color: rgb(108, 117, 125); caret-color: rgb(108, 117, 125);">
            <p>Ainda não existem descontos para nenhum meio de pagamento.</p>
          </span>
        </div>
        <v-col cols="1">
          <v-spacer />
        </v-col>

        <v-dialog v-model="formDiscount" max-width="70%">
          <v-card class="dialog-content">
            <v-card-title style="padding-top: 20px;">
              <h3 data-testid="page-title" class="description">
                Cadastro desconto de meio de pagamento
              </h3>
              <v-spacer />
              <v-btn color="#07BEE7" icon dark @click.stop="formDiscount = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-6">
              <v-row class="margin-fix pa-6 pb-0">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                  <ComboboxInput
                    label-width="30%"
                    v-model="discount.paymentType"
                    persistent-hint
                    :validationRules="[validadePaymentType]"
                    label="Meio de pagamento*"
                    placeHolder="Selecione ou digite"
                    :options="paymentTypeOptions"
                  />
                </v-col>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
                <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                  <TextFieldInput
                    v-model="discount.discountPercentage"
                    inputType="number"
                    label="Desconto (%)*"
                    :validationRules="[validadeDiscountPercentage]"
                    placeHolder="Informe o percentual"
                    labelWidth="30%"
                  />
                </v-col>
              </v-row>
              <v-row class="margin-fix pa-6 pb-0">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                  <TextFieldInput
                    v-model="discount.initialDate"
                    inputType="date"
                    label="Data inicial*"
                    :validationRules="[validadeBeforeDateDiscont]"
                    placeHolder="Informe a data"
                    labelWidth="30%"
                  />
                </v-col>
                <v-col cols="1">
                  <v-spacer />
                </v-col>
                <v-col xl="5" lg="5" sm="12" md="5" class="pb-0">
                  <TextFieldInput
                    v-model="discount.finalDate"
                    inputType="date"
                    label="Data final*"
                    :validationRules="[validadeAfterDateDiscont]"
                    placeHolder="Informe a data"
                    labelWidth="30%"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions style="padding: 0 30px 30px 30px;">
              <v-spacer />
              <v-btn color="#07BEE7" outlined @click.stop="cancelItem">Cancelar</v-btn>
              <v-btn color="#07BEE7" :disabled="validadeFormDiscont" outlined @click.stop="addItem">Salvar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-tab-item>
    </v-tabs-items>
  </FormContent>
</template>

<script>
import moment from 'moment';
import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import DataCrud from '@/components/template/crud/DataCrud';
import { get } from '@/service/api';
import CategoryFormMixin from './mixins/CategoryFormMixin';
import MaskInput from '@/components/template/form/input/MaskInput';

const MaskOptions = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
  prefix: 'R$ ',
};

const MaskOptionsPercentual = {
  numeral: true,
  numeralDecimalScale: 2,
  numeralDecimalMark: ',',
  delimiter: '.',
};

const DateMaskOptions = {
  date: true,
  delimiter: '/',
  dateMin: '01/01/1900',
  dateMax: '12/31/2099',
  placeholder: 'DD/MM/YYYY',
};

export default {
  name: 'CardsAndBilletForm',
  components: {
    FormContent,
    DataCrud,
    TextFieldInput,
    ComboboxInput,
    MaskInput,
  },
  mixins: [CategoryFormMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Meios de pagamento',
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      MaskOptions,
      DateMaskOptions,
      MaskOptionsPercentual,
      paymentTypeOptions: ['pix', 'boleto'],
      headerDiscounts: [
        { text: 'Meio de pagamento', value: 'paymentType' },
        { text: 'Desconto (%)', value: 'discountPercentage' },
        { text: 'Data inicial', value: 'initialDate' },
        { text: 'Data final', value: 'finalDate' },
      ],
      decoratorsDiscounts: [
        {
          column: 'initialDate',
          chipBackgroundColorFunction: (item) => '#e0e0e000',
          formatterFunction: (item) => {
            const date = new Date(item.initialDate);
            return [date.getDate() + 1, date.getMonth() + 1, date.getFullYear()].join('/');
          },
        },
        {
          column: 'finalDate',
          chipBackgroundColorFunction: (item) => '#e0e0e000',
          formatterFunction: (item) => {
            const date = new Date(item.finalDate);
            return [date.getDate() + 1, date.getMonth() + 1, date.getFullYear()].join('/');
          },
        },
      ],
      decorators: [
        {
          column: 'interestRates',
          formatterImput: {
            param: 'interestRates',
            type: 'number',
          },
        },
      ],
      selected: false,
      formList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      koerichList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      cardList: {
        list: [],
        page: 1,
        itemsPage: 10,
      },
      discount: {},
      discountRaw: {},
      formDiscount: false,
      formDialog: {
        show: false,
        initialDate: null,
        finalDate: null,
        minValue: null,
        maxInstallments: null,
        installments: [],
      },
      tab: null,
      tabsArray: ['tab', 'tab1', 'tab2'],
      header: [
        { text: 'Parcela', value: 'installmentNumber' },
        { text: 'Taxa de juros (%)', value: 'interestRates' },
        { text: 'Coeficiente do parcelamento (%)', value: 'coefficients' },
      ],
      itens: [],
      maxOption: 100,
    };
  },
  computed: {
    validadeFormDiscont() {
      return (
        !this.discount ||
        !this.discount.finalDate ||
        !this.discount.initialDate ||
        !this.discount.discountPercentage ||
        !this.discount.paymentType ||
        typeof this.validadeBeforeDateDiscont(this.discount?.initialDate) === 'string' ||
        typeof this.validadeAfterDateDiscont(this.discount?.finalDate) === 'string'
      );
    },
    Rules() {
      return {
        dateFullyFilled: (value) => {
          if (!value) return true;
          const numberValue = value.replace(/\D/g, '');

          if (numberValue.length < 8) {
            return 'Preencha a data corretamente. Ex: dd/mm/yyyy';
          }
          if (!value) return 'Campo Obrigatório';
          return true;
        },
        dateInitial: (value) => {
          if (!value) return true;
          const initial = moment(this.form.installmentConfig.initialDate, 'DDMMYYYY');
          const final = moment(this.form.installmentConfig.finalDate, 'DDMMYYYY');
          if (initial.isAfter(final)) return 'Data inicial deve ser menor do que data final';
          return true;
        },
        dateFinal: (value) => {
          if (!value) return true;
          const initial = moment(this.form.installmentConfig.initialDate, 'DDMMYYYY');
          const final = moment(this.form.installmentConfig.finalDate, 'DDMMYYYY');
          if (initial.isAfter(final)) return 'Data final deve ser maior do que data inicial';
          return true;
        },
        required: (value) => {
          if (!value && this.form.installmentConfig.maxInstallments) return 'Campo Obrigatório';
          const numberValue = value.replace(/\D/g, '');
          if (numberValue.length < 1 && !!this.form.installmentConfig.maxInstallments) {
            return 'Campo Obrigatório';
          }
          return true;
        },
        dateInitialKoerich: (value) => {
          if (!value) return true;
          const initial = moment(this.form.installmentConfigKoerich.initialDate, 'DDMMYYYY');
          const final = moment(this.form.installmentConfigKoerich.finalDate, 'DDMMYYYY');
          if (initial.isAfter(final)) return 'Data inicial deve ser menor do que data final';
          return true;
        },
        dateFinalKoerich: (value) => {
          if (!value) return true;
          const initial = moment(this.form.installmentConfigKoerich.initialDate, 'DDMMYYYY');
          const final = moment(this.form.installmentConfigKoerich.finalDate, 'DDMMYYYY');
          if (initial.isAfter(final)) return 'Data final deve ser maior do que data inicial';
          return true;
        },
        requiredKoerich: (value) => {
          if (!value && this.form.installmentConfigKoerich.maxInstallments) return 'Campo Obrigatório';
          const numberValue = value.replace(/\D/g, '');
          if (numberValue.length < 1 && !!this.form.installmentConfigKoerich.maxInstallments) {
            return 'Campo Obrigatório';
          }
          return true;
        },
      };
    },
  },
  methods: {
    validadePaymentType(value) {
      return (value && value.length > 0) || 'Campo `Meio de Pagamento` é Obrigatório.';
    },
    validadeDiscountPercentage(value) {
      return (value && value.length > 0) || 'Campo `Desconto` é Obrigatório.';
    },
    validadeBeforeDateDiscont(value) {
      return this.validadeBeforeDate(value, this.discount?.finalDate);
    },
    installmentConfigInitialDateValid(value) {
      return this.validadeBeforeDate(value, this.form.installmentConfig?.finalDate);
    },
    installmentConfigKoerichInitialDateValid(value) {
      return this.validadeBeforeDate(value, this.form.installmentConfigKoerich?.finalDate);
    },
    validadeAfterDateDiscont(value) {
      return this.validadeAfterDate(value, this.discount?.initialDate);
    },
    installmentConfigFinalDateValid(value) {
      return this.validadeAfterDate(value, this.form.installmentConfig?.initialDate);
    },
    installmentConfigKoerichFinalDateValid(value) {
      return this.validadeAfterDate(value, this.form.installmentConfigKoerich?.initialDate);
    },
    validadeBeforeDate(value, after) {
      return !(value && value.length > 0)
        ? 'Campo `Data Inicial` é Obrigatório.'
        : new Date(value) <= new Date(after) || !after || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
    },
    validadeAfterDate(value, before) {
      return !(value && value.length > 0)
        ? 'Campo `Data Final` é Obrigatório.'
        : new Date(value) >= new Date(before) || !before || 'Campo `Data Final` deve ser superior `Data Inicial`.';
    },
    async cancelItem() {
      this.discount = {};
      this.formDiscount = false;
    },
    async add() {
      this.discount = {};
      this.formDiscount = true;
    },
    async edit(item) {
      this.discountRaw = item;
      this.discount = { ...item };
      this.formDiscount = true;
    },
    async remove(item) {
      const index = this.form.discountsByPaymentType.indexOf(item);
      this.form.discountsByPaymentType.splice(index, 1);
    },
    async addItem() {
      if (!this.discount.id) {
        this.form.discountsByPaymentType.push(this.discount);
      } else {
        this.discountRaw.discountPercentage = this.discount.discountPercentage;
        this.discountRaw.initialDate = this.discount.initialDate;
        this.discountRaw.finalDate = this.discount.finalDate;
        this.discountRaw.paymentType = this.discount.paymentType;
      }
      this.formDiscount = false;
    },
    async reset() {
      if (this.$route.params.id && this.$route.params.id !== 'undefined') {
        const { data } = await get(`ui-integrator/category/${this.$route.params.id}?integrationPlatform=${this.$route.params.integrationPlatform}`);
        this.form.installmentConfigKoerich = data.installmentConfigKoerich;
        this.form.installmentConfig = data.installmentConfig;

        if (data.installmentConfig) {
        this.form.installmentConfig.initialDate = moment(data.installmentConfig?.initialDate, 'YYYY-MM-DD').format('DDMMYYYY');
        this.form.installmentConfig.finalDate = moment(data.installmentConfig?.finalDate, 'YYYY-MM-DD').format('DDMMYYYY');
        this.form.installmentConfig.minValue = Number(data.installmentConfig?.minValue).toFixed(2);
        }
        if (data.installmentConfigKoerich) {
        this.form.installmentConfigKoerich.initialDate =
        moment(data.installmentConfigKoerich?.initialDate, 'YYYY-MM-DD').format('DDMMYYYY');
        this.form.installmentConfigKoerich.finalDate =
        moment(data.installmentConfigKoerich?.finalDate, 'YYYY-MM-DD').format('DDMMYYYY');
        this.form.installmentConfigKoerich.minValue = Number(data.installmentConfigKoerich?.minValue).toFixed(2);
        }

        this.changePage(this.form.installmentConfigKoerich.installments, 'koerichList', 1);
        this.changePage(this.form.installmentConfig.installments, 'cardList', 1);
      } else {
        this.form.installmentConfigKoerich = {
          installments: [],
        };
        this.form.installmentConfig = {
          installments: [],
        };
      }
      this.validateInstallmentConfig = true;
      this.validateInstallmentConfigKoerich = true;
    },
    changeInterestRates(value, list) {
      // eslint-disable-next-line vue/max-len
      const installment = list.installments.filter((data) => value.item.installmentNumber === data.installmentNumber)[0];
      let { interestRates } = installment;
      if (Number(interestRates) < 0) {
        installment.interestRates = '0';
        interestRates = '0';
      }
      // eslint-disable-next-line
      list.installments.filter((data) => value.item.installmentNumber === data.installmentNumber)[0].coefficients = this.calculaCoeficiente(installment);
    },
    calculaCoeficiente(installment) {
      if (installment.interestRates <= 0) {
        return 0;
      }

      const interestRates = installment.interestRates / 100;
      const { installmentNumber } = installment;

      return (interestRates / (1 - (1 + interestRates) ** -installmentNumber)).toFixed(4);
    },
    changePage(installments, list, page) {
      this[list].page = page;
      const { itemsPage } = this[list];
      this[list].list = installments.slice(itemsPage * (page - 1), itemsPage * (page - 1) + itemsPage);
    },
    changeItemsPerPage(installments, list, itemsPage) {
      this[list].itemsPage = itemsPage;
      this[list].page = 1;
      this[list].list = installments.slice(0, itemsPage);
    },
    changeNumberInstallments(number, type, page) {
      if (this.form[type].installments.length < number) {
        // eslint-disable-next-line no-plusplus
        for (let i = this.form[type].installments.length + 1; i <= number; i++) {
          this.form[type].installments.push({
            installmentNumber: i,
            interestRates: 0,
            coefficients: 0,
          });
        }
      }
      if (this.form[type].installments.length > number) {
        // eslint-disable-next-line no-plusplus
        for (let i = this.form[type].installments.length; i > number; i--) {
          this.form[type].installments.pop();
        }
      }
      this[page].page = 1;
      this[page].itemsPage = 10;
      this.changePage(this.form[type].installments, page, 1);
      if (type === 'installmentConfig') {
        if (this.form.installmentConfig.maxInstallments) {
          this.validate('installmentConfigInitialDate', this.form.installmentConfig.initialDate);
          this.validate('installmentConfigFinalDate', this.form.installmentConfig.finalDate);
          this.validate('installmentConfigMinValue', this.form.installmentConfig.minValue);
        } else {
          this.validate('installmentConfigInitialDate', '2000-01-01');
          this.validate('installmentConfigFinalDate', '2000-01-01');
          this.validate('installmentConfigMinValue', '1');
        }
      }
      if (type === 'installmentConfigKoerich') {
        if (this.form.installmentConfigKoerich.maxInstallments) {
          this.validate('installmentConfigKoerichInitialDate', this.form.installmentConfigKoerich.initialDate);
          this.validate('installmentConfigKoerichFinalDate', this.form.installmentConfigKoerich.finalDate);
          this.validate('installmentConfigKoerichMinValue', this.form.installmentConfigKoerich.minValue);
        } else {
          this.validate('installmentConfigKoerichInitialDate', '2000-01-01');
          this.validate('installmentConfigKoerichFinalDate', '2000-01-01');
          this.validate('installmentConfigKoerichMinValue', '1');
        }
      }
    },
    validate(field, value) {
      this.$emit('validateRequired', this.$options.name, field, this.form);
    },
  },
  mounted() {
    if (!this.form.installmentConfigKoerich) {
      this.form.installmentConfigKoerich = {
        installments: [],
      };
    }
    if (!this.form.installmentConfig) {
      this.form.installmentConfig = {
        installments: [],
      };
    }
    if (!this.form.boletoConfig) {
      this.form.boletoConfig = {};
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < this.maxOption; i++) {
      this.itens.push(i);
    }
    this.reset();
  },
};
</script>

<style scoped>
.left-content {
  width: 20%;
}

.right-content {
  width: 40%;
}
</style>
