<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-col cols="12" sm="6">
      <v-combobox
      v-model="selects"
      :items="options"
      :search-input.sync="search"
      hide-selected
      :hint="placeHolder"
      :label="label"
      multiple
      persistent-hint
      small-chips
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'ComboboxDateInput',
  props: {
    label: { type: String, default: '' },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    placeHolder: { type: String, default: '' },
    values: { type: Array },
    options: { type: Array, default: () => [] },
  },
  data: () => ({
    search: null,
  }),
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    selects: {
      get() {
        return this.values;
      },
      set(values) {
        this.$emit('input', values);
      },
    },
  },
};
</script>

<style>
</style>
