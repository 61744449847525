<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextFieldInput v-model='form.indexing.name' label='Título' placeHolder='Digite o título'
                        input-type='text'  labelWidth='20%' :labelHAlign='colLabelHAlign'/>
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextAreaInput v-model='form.indexing.description' label='Descrição' placeHolder='Digite a descrição adicional do selo'
                       labelWidth='20%' :labelHAlign='col1LabelHAlign'/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <ComboboxTagInput v-model='form.indexing.keyWords' :values='form.indexing.keyWords' :options='form.indexing.keyWords' label='Palavras-chave'
                          placeHolder='Digite as palavras-chave e tecla enter' labelWidth='20%' :labelHAlign='colLabelHAlign'/>
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import TextAreaInput from '@/components/template/form/input/TextAreaInput';
import ComboboxTagInput from '@/components/template/form/input/ComboboxTagInput';

export default {
  name: 'IndexingForm',
  components: {
    FormContent,
    TextFieldInput,
    TextAreaInput,
    ComboboxTagInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Indexação',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    selected: false,
    colLabelWidth: '200px',
    colLabelHAlign: 'left',
    col1LabelWidth: '250px',
    col1LabelHAlign: 'left',
    col2LabelWidth: '250px',
    col2LabelHAlign: 'left',
  }),
  methods: {
    validateCode(value) {
      let message = 'Campo Obrigatório.';
      let hasError = false;
      if (value && value.length < 3) {
        message = 'Mínimo obrigatório de 3 caracteres.';
        hasError = true;
      }
      if (value && !value.match(/^[-_A-z0-9]*$/g)) {
        message = 'Padrão incorreto.';
        hasError = true;
      }
      if (value && !hasError) {
        this.$emit('success', 'code');
      } else {
        this.$emit('error', 'code');
      }
      return (!!value && !hasError) || message;
    },
    validateName(value) {
      let message = 'Campo Obrigatório.';
      let hasError = false;
      if (value && !value.match(/^[a-zA-Zò-úÒ-Ú ]+$/g)) {
        message = 'Nome inválido! Permitido apenas letras e espaço.';
        hasError = true;
      }
      if (value && value.length < 3) {
        message = 'Mínimo obrigatório de 3 caracteres.';
        hasError = true;
      }
      if (value && !hasError) {
        this.$emit('success', 'name');
      } else {
        this.$emit('error', 'name');
      }
      return (!!value && !hasError) || message;
    },
  },
};

</script>

<style>
</style>
