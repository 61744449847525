<template>
  <v-row class='row-space'>
    <div v-if="!removeLabel" class='font-label' :style='labelStyle'>
      {{ label }}
    </div>
    <v-text-field
      :placeholder='placeHolder'
      :type='inputType'
      validate-on-blur
      filled
      dense
      light
      @focusout="focusout"
      @change="change"
      :hide-details="removeLabel"
      v-model='textValue'
      :rules="validationRules"
      :disabled="disabled"
      :prepend-inner-icon='prependInnerIcon'
      :error="error || err"
      :error-messages="error_message"
      @blur="parseMask(value)"
    />
  </v-row>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'BrlValueFieldInput',
  props: {
    label: { type: String, default: '' },
    removeLabel: { type: Boolean, default: false },
    labelWidth: { type: String, default: '100px' },
    labelHAlign: { type: String, default: 'left' },
    labelVAlign: { type: String, default: 'middle' },
    paddingLeft: { type: String, default: '0%' },
    placeHolder: { type: String, default: '' },
    validationRules: { type: Array },
    value: { type: [String, Number, Object], default: '' },
    inputType: { type: String, default: 'text' },
    prependInnerIcon: { type: String },
    disabled: { type: Boolean },
    error: { type: Boolean, default: false },
  },
  data: () => ({
    err: false,
    error_message: null,
    mask: {
      patterns: [
        { from: '[^0-9,]', to: '' },
        { from: '^,$', to: '' },
        { from: '^(0*)$', to: ',00' },
        { from: '^(0*)(\\d*),(\\d{1})$', to: '$2,$30' },
        { from: '^(0*)(\\d*),(\\d{2})\\d*$', to: '$2,$3' },
        { from: '^(0*)(\\d+)$', to: '$2,00' },
        { from: '^(0*)(\\d+)(\\d{3}),(\\d{2})$', to: '$2.$3,$4' },
        { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3}),(\\d{2})$', to: '$2.$3.$4,$5' },
        { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3})[.](\\d{3}),(\\d{2})$', to: '$2.$3.$4.$5,$6' },
        { from: '^(0*)(\\d+)(\\d{3})[.](\\d{3})[.](\\d{3})[.](\\d{3}),(\\d{2})$', to: '$3.$4.$5.$6,$7' },
        { from: '^,00$', to: '0,00' },
      ],
    },
  }),
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; padding-left: ')
        .concat(this.paddingLeft)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.validate(value);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    validate(value) {
      if (this.validationRules) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.validationRules.length; i++) {
          const validate = this.validationRules[i];
          const result = validate(value);
          this.err = result.hasError;
          this.error_message = result.hasError ? result.message : null;
        }
        this.$forceUpdate();
      }
    },
    parseMask(value) {
      for (let i = 0; i < this.mask.patterns.length; i += 1) {
        value = value.replace(new RegExp(this.mask.patterns[i].from, 'g'), this.mask.patterns[i].to);
      }
      this.textValue = value;
      this.$emit('change');
    },
    focusout() {
      this.$emit('focusout');
    },
    change() {
      this.$emit('change');
    },
  },
});

</script>

<style>
</style>
