<template>
  <v-main class="pa-6">
    <v-row class="margin-fix pa-3">
      <ComboboxInput v-model='form.type' :options='types' label='Tipo*' placeHolder='Digite ou selecione o tipo da propriedade'
                     labelWidth='20%' :validationRules='validation.type'/>
    </v-row>
    <v-row class="margin-fix">
      <v-col cols='12' >
      <TextFieldInput v-model='form.description' label='Descrição*' placeHolder='Digite a descrição adicional do selo'
                     labelWidth='20%' :validationRules='validation.description' :maxLength="20"/>
      </v-col>
    </v-row>
    <v-row class="margin-fix pa-3">
      <BrlValueFieldInput
        v-model='form.value'
        label='Valor*'
        placeHolder='Digite o valor exibido no selo'
        labelWidth='20%'
        :validationRules='validation.value'/>
    </v-row>
    <v-row class='pb-2 margin-fix'>
      <h4 class="subtext subtext_form_devide_h4"> Propriedades </h4>
    </v-row>
    <v-row class="margin-fix pa-3">
      <ComboboxInput v-model='form.priority' label='Prioridade' placeHolder='Selecione uma prioridade' :options='priorities'
                     labelWidth='20%'/>
    </v-row>
    <v-row class="margin-fix pa-3">
      <ComboboxInput v-model='form.background' label='Cor de fundo' placeHolder='Selecione uma cor' :options='backgrounds'
                     labelWidth='20%'/>
    </v-row>
    <v-row class='pb-2 margin-fix'>
      <h4 class="subtext subtext_form_devide_h4"> Vencimento </h4>
    </v-row>
    <v-row class='margin-fix pa-6 pb-0'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput v-model="form.initialExpirationDate"
                        inputType='date'
                        label='Data inicial*'
                        :validationRules="[validadeInitialExpirationDate]"
                        placeHolder='Informe a data'
                        labelWidth='20%'/>
      </v-col>
      <v-col cols='1'>
        <v-spacer/>
      </v-col>
      <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <TextFieldInput v-model="form.finalExpirationDate"
                        inputType='date'
                        label='Data final*'
                        :validationRules="[validadeFinalExpirationDate]"
                        placeHolder='Informe a data'
                        labelWidth='20%'/>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import BrlValueFieldInput from '@/components/template/form/input/BrlValueFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import TextFieldInput from '../../../components/template/form/input/TextFieldInput';

export default {
  name: 'StampDataForm',
  components: {
    BrlValueFieldInput,
    ComboboxInput,
    TextFieldInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Cadastro de Selo',
    },
    form: {
      type: Object,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    validation: {
      type: Object,
      required: false,
    },
  },
  methods: {
    validadeInitialExpirationDate(value) {
      return this.validadeBeforeDate(value, this.form?.finalExpirationDate);
    },
    validadeFinalExpirationDate(value) {
      return this.validadeAfterDate(value, this.form?.initialExpirationDate);
    },
    validadeBeforeDate(value, after) {
      return !(value && value.length > 0) ? 'Campo `Data Inicial` é Obrigatório.' : (new Date(value) <= new Date(after) || !after) || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
    },
    validadeAfterDate(value, before) {
      return !(value && value.length > 0) ? 'Campo `Data Final` é Obrigatório.' : (new Date(value) >= new Date(before) || !before) || 'Campo `Data Final` deve ser superior `Data Inicial`.';
    },
  },
  data: () => ({
    col1LabelWidth: '150px',
    col1LabelHAlign: 'left',
    col2LabelWidth: '150px',
    col2LabelHAlign: 'left',
    colLabelWidth: '200px',
    colLabelHAlign: 'left',
    priorities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    backgrounds: [
      'amarelo', 'branco', 'vermelho', 'chumbo', 'preto',
    ],
  }),
};

</script>
