<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <label class="label_notes">Cadastre ou edite informações gerais da categoria.</label>
      </v-col>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <label class="label_notes">Observação: Campos indicados com asterisco são de preenchimento obrigatório.</label>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput
          :disabled="form.id != null && $route.params.id != null"
          v-model='form.id' label='Código*'
          placeHolder='Informe o ID/código da categoria'
          input-type='text'
          labelWidth='40%'
          :labelHAlign='colLabelHAlign'
          :validationRules='[validationRules.id]'
          @focusout="validate('id')"/>
      </v-col>
      <v-col md="1"><span/></v-col>
      <!-- <v-col xl="5" lg="5" sm="12" md="5" class='pb-0'>
        <SwitchInput v-model='form.status' label='Status'
                     labelWidth='20%' :labelHAlign='colLabelHAlign'/>
      </v-col> -->
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextFieldInput
          v-model='form.name'
          label='Nome*'
          placeHolder='Informe o nome da categoria'
          input-type='text'
          labelWidth='20%'
          :labelHAlign='colLabelHAlign'
          :validationRules='[validationRules.name]'
          @focusout="validate('name')"/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextFieldInput
          v-model='form.description'
          label='Descrição*'
          placeHolder='Informe a descrição da categoria'
          input-type='text'
          labelWidth='20%'
          :labelHAlign='colLabelHAlign'
          :validationRules='[validationRules.description]'
          @focusout="validate('description')"/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <Select2Input
          item-text="name"
          item-value="id"
          label-width="20%"
          v-model="form.parentId"
          label="Categoria Pai"
          placeholder="Digite para buscar Categoria Pai"
          prepend-icon="mdi-database-search"
          :multiple="false"
          :search-id="getCategory"
          :search="searchCategorys">
        </Select2Input>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextFieldInput v-model='form.googleId'
                        label='Google ID'
                        inputType="number"
                        placeHolder='Informe a ID/referência da categoria a ser utilizada nas pesquisas do Google'
                        input-type='text'
                        labelWidth='20%'
                        :labelHAlign='colLabelHAlign'/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <ComboboxInput
          :disabled="form.id != null && $route.params.id != null"
          v-model='form.integrationPlatform'
          label='Plataforma*'
          placeHolder='OCC'
          :options='platforms'
          labelWidth='40%'
          :labelHAlign='colLabelHAlign'
          labelValue='text'
          itemValue='value'/>
      </v-col>
      <v-col md="1"><span/></v-col>
      <v-col xl="2" lg="2" sm="2" md="2">
        <div class='font-label' :style='labelStyle'>Prioridade*</div>
      </v-col>
      <v-col xl="3" lg="3" sm="3" md="3" class='pb-0'>
        <ComboboxInput
          placeHolder='Selecione ou digite'
          v-model='form.priority'
          labelValue='text'
          itemValue='value'
          :options='priorities'/>
      </v-col>
    </v-row>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <TextFieldInput v-model='form.maxAmountToSell'
                        :onlyInteger="true"
                        label='Quantidade Máxima'
                        placeHolder='Informe o máximo de produtos permitidos para venda por pedido'
                        inputType='number' labelWidth='20%' :labelHAlign='colLabelHAlign'/>
      </v-col>
    </v-row>
  </FormContent>
</template>

<script>

import FormContent from '@/components/template/form/FormContent';
import TextFieldInput from '@/components/template/form/input/TextFieldInput';
import ComboboxInput from '@/components/template/form/input/ComboboxInput';
import { get } from '@/service/api';
import { PlatformOptions } from '../../../static-data/PlatformOptions';
import { PrioritiesOptions } from '../../../static-data/PrioritiesOptions';
import Select2Input from '../../../components/template/form/input/Select2Input';
import { getCategory, listCategory } from '../../../service/category/category-service';

export default {
  name: 'GeneralInformationForm',
  components: {
    FormContent,
    TextFieldInput,
    Select2Input,
    ComboboxInput,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Geral',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    // eslint-disable-next-line vue/max-len
    this.form.priority = this.form.priority ? this.form.priority : PrioritiesOptions[0];
    this.form.integrationPlatform = this.form.integrationPlatform ? this.form.integrationPlatform : PlatformOptions[0];
  },
  data: () => ({
    selected: true,
    platforms: PlatformOptions,
    priorities: PrioritiesOptions,
    colLabelHAlign: 'left',
    maxLength: 25,
    parentCategories: [],
    parentCategoriesCount: 0,
    isLoading: false,
  }),
  methods: {
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
    async getCategory(id, plataforma = this.form.integrationPlatform) {
      const { data } = await getCategory(id, plataforma);
      return data;
    },
    async searchCategorys(param, filter, itemsPerPage) {
      const data = await listCategory(param, filter, itemsPerPage);
      return data.register;
    },
  },
  computed: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat('; text-align: ')
        .concat(this.labelHAlign)
        .concat('; padding-left: ')
        .concat(this.paddingLeft)
        .concat('; vertical-align: ')
        .concat(this.labelVAlign);
    },
    fields() {
      if (!this.form.parentId) {
        return [];
      }

      return Object.keys(this.form.parentId).map((key) => ({
        key,
        value: this.form.parentId[key] || 'n/a',
      }));
    },
    items() {
      return this.parentCategories.map((category) => {
        const id = category.id.length > this.maxLength
          ? `${category.id.slice(0, this.maxLength)}...`
          : category.id;
        const name = category.name.length > this.maxLength
          ? `${category.name.slice(0, this.maxLength)}...`
          : category.name;
        const description = category.description.length > this.maxLength
          ? `${category.description.slice(0, this.maxLength)}...`
          : category.description;
        category.label = `Cód: ${id} * Nome: ${name} * Desc: ${description}`;
        return category;
      });
    },
  },
};
</script>

<style>
.label_notes {
  color: gray;
  font-size: 12px;
}
</style>
