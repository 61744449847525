<template>
  <FormContent :selected='selected' class='pa-6'>
    <v-row class='margin-fix pa-3'>
      <v-col xl="12" lg="12" sm="12" md="12" class='pb-0'>
        <label class="label_notes">Gerencie aqui os selos que são exibidos nesta categoria.</label>
      </v-col>
    </v-row>
    <v-btn color='#07BEE7'
           style='color: #FFFFFF; float: right' depressed @click='add()'>
      + Selo
    </v-btn>
    <DataCrud
      title=' '
      entityName='Selos'
      :tableHeader='header'
      :tableData='content'
      :columnDecorators='decorators'
      :enableEditFunction='true'
      :enableRemoveFunction='true'
      :justify-center="false"
      :showFormDialog="true"
      @editEvent="editAction"
      @formDelete="formDelete">
    </DataCrud>
    <v-dialog v-model='show_dialog' max-width='90%' persistent>
      <v-card class="dialog-content">
        <v-card-title style='padding-top: 20px;'>
          <h3 data-testid='page-title' class='description'>
            Cadastro de selos
          </h3>
          <v-spacer/>
          <v-btn color='#07BEE7' icon dark @click.stop='show_dialog = false'>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pa-9'>
          <StampDataForm :form="form" :key="formKey" :types="types" :validation="{
        type: [validateType], description: [validateDescription], value: [validateValue]
      }"/>
        </v-card-text>
        <v-card-actions style='padding: 0 30px 30px 30px;'>
          <v-spacer/>
          <v-btn color='#07BEE7' outlined @click.stop='formCancel'>Cancelar</v-btn>
          <v-btn color='#07BEE7'
                 outlined @click.stop='formSubbmit' :disabled='disableSave'>Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </FormContent>
</template>

<script>

import Vue from 'vue';
import FormContent from '@/components/template/form/FormContent';
import DataCrud from '@/components/template/crud/DataCrud';
import StampDataForm from '@/views/category/forms/StampDataForm';
import notification from '@/service/notification';

export default Vue.extend({
  name: 'StampForm',
  components: {
    FormContent,
    DataCrud,
    StampDataForm,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Selos',
    },
    content: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selected: false,
    header: [
      { text: 'Tipo', align: 'start', value: 'type' },
      { text: 'Descrição', value: 'description' },
      { text: 'Valor', value: 'value' },
      { text: 'Prioridade', value: 'priority' },
      { text: 'Data Final', value: 'finalExpirationDate' },
    ],
    decorators: [
      {
        column: 'finalExpirationDate',
        formatterFunction: (item) => {
          function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
          }
          function formatDate(date) {
            return [
              padTo2Digits(date.getDate() + 1),
              padTo2Digits(date.getMonth() + 1),
              date.getFullYear(),
            ].join('/');
          }
          if (item.finalExpirationDate) {
            return formatDate(new Date(item.finalExpirationDate));
          }
          return '';
        },
      },
    ],
    base_form: {
      itemId: null,
      type: '',
      description: '',
      value: '',
      priority: '0',
      background: '',
      initialExpirationDate: '',
      finalExpirationDate: '',
      active: true,
    },
    form: {
      itemId: null,
    },
    errors: {
      type: true,
    },
    disableSave: false,
    oldData: {},
    show_dialog: false,
    formKey: null,
    types: [
      'juros', 'blackfriday', 'desconto', 'lancamento', 'frete', 'coupon',
    ],
  }),
  mounted() {
    this.form = { ...this.base_form };
  },
  watch: {
    form: {
      handler(form) {
        this.validateForm(form);
      },
      deep: true,
    },
  },
  methods: {
    add() {
      this.form = { ...this.base_form };
      this.validateForm(this.form);
      this.show_dialog = true;
    },
    editAction(item) {
      this.oldData = { ...item };
      this.form = item;
      this.validateForm(this.form);
      this.show_dialog = true;
    },
    formSubbmit() {
      const messageDescription = this.validateDescription(this.form.description);
      const messageType = this.validateType(this.form.type);
      const messageValue = this.validateValue(this.form.value);
      if (messageDescription !== true) {
        notification(messageDescription);
      }
      if (messageType !== true) {
        notification(messageType);
      }
      if (messageValue !== true) {
        notification(messageValue);
      }
      if (messageDescription === true && messageType === true && messageValue === true) {
        this.$emit('addStamp', this.form);
        this.form = { ...this.base_form };
        this.updateFormKey();
        this.show_dialog = false;
      }
    },
    formCancel() {
      this.form = Object.assign(this.form, this.oldData);
      this.show_dialog = false;
    },
    formDelete(item) {
      if (this.form.id !== null) {
        this.$emit('deleteStamp', this.form);
        this.show_dialog = false;
      }
      if (item) {
        this.$emit('deleteStamp', item);
      }
    },
    changeShowDialog(show) {
      this.show_dialog = show;
    },
    updateFormKey() {
      this.formKey = Math.random();
    },
    validateDescription(value) {
      if (!!value && value.length < 1) {
        return 'Campo `Descrição` é Obrigatório.';
      }
      if (!!value && value.length > 20) {
        return 'Campo `Descrição` deve conter no máximo 20 caracteres.';
      }
      return true;
    },
    validateType(value) {
      return (!!value && this.types.includes(value)) || 'Campo `Tipo` é Obrigatório.';
    },
    validateValue(value) {
      return (!!value) || 'Campo `Valor` é Obrigatório.';
    },
    hasMessageError(valid) {
      return valid.constructor.name === 'String';
    },
    validateForm(form) {
      const formValidations = [
        this.validateValue(form.value),
        this.validateDescription(form.description),
        this.validateType(form.type),
      ];
      this.disableSave = formValidations.some((fieldValidation) => this.hasMessageError(fieldValidation));
    },
  },
});

</script>
