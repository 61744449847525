<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <v-col cols='12'>
      <DialogWarning
        :open='saveDialogAlert'
        message="Deseja salvar o cadastro desta categoria?"
        screenTitle="Ao salvar as alterações desta categoria, os produtos pertencentes à ela serão automaticamente republicados."
        v-on:confirm="dialogConfirmation"
        v-on:cancel="dialogDenial"
        denyDialog="VOLTAR"
        acceptDialog="SIM, SALVAR CADASTRO"/>
    </v-col>
    <FormStepController
      @formSubbmit="formSubbmit"
      :formModified="formModified"
      :showDeleteActionButton="false"
      :form="form"
      :editing="editing"
      :steps="steps"
      backAlertMessage="Deseja cancelar o cadastro desta categoria?"
      alertScreenTitle="cadastro de categoria"
      :title="!editing ? 'Cadastro de Categoria' : 'Edição de Categoria'">
      <GeneralInformationForm
        title="Informações Gerais"
        :form="form"
        :validationRules="steps['GeneralInformationForm'].rules"
        @validateRequired="validateRequired"/>
      <CardsAndBilletForm
        :form="form"
        ref="biling"
        :validationRules="steps['CardsAndBilletForm'].rules"
        @validateRequired="validateRequired"/>
      <StampForm :content='form.stamps' v-on:addStamp='addStamp' v-on:deleteStamp="deleteStamp"/>
      <IndexingForm :form="form"/>
    </FormStepController>
  </v-container>
</template>

<script>
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import FormStepController from '@/components/template/form/FormStepController';
import GeneralInformationForm from '@/views/category/forms/GeneralInformationForm';
import CardsAndBilletForm from '@/views/category/forms/CardsAndBilletForm';
import StampForm from '@/views/category/forms/StampForm';
import IndexingForm from '@/views/category/forms/IndexingForm';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
import utils from '@/utils/utils';
import DialogWarning from '@/components/template/alert/DialogWarning';
import { createCategory, updateCategory, getCategory } from '@/service/category/category-service';
import FormStepMixin from '../../components/template/form/mixins/FormStepMixin';
import CategoryFormMixin from './forms/mixins/CategoryFormMixin';

export default {
  name: 'CategoryForm',
  data: () => ({
    saveDialogAlert: false,
    editing: false,
    savedForm: null,
    formModified: false,
    form: {
      id: null,
      status: true,
      name: null,
      description: null,
      parentId: null,
      googleId: null,
      showDimensionTable: false,
      integrationPlatform: null,
      priority: null,
      maxAmountToSell: null,
      installmentConfig: null,
      installmentConfigKoerich: null,
      discountsByPaymentType: [],
      boletoConfig: {
        discount: '0',
      },
      stamps: [],
      indexing: {
        name: null,
        description: null,
        keyWords: [],
      },
    },
    validateInstallmentConfig: true,
    validateInstallmentConfigKoerich: true,
  }),
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        if (this.form !== null && this.savedForm !== null) {
          this.formModified = !this.identicalObjects(this.form, this.savedForm);
        }
      },
    },
  },
  components: {
    FormStepController,
    GeneralInformationForm,
    CardsAndBilletForm,
    StampForm,
    IndexingForm,
    DialogWarning,
  },
  created() {
    if (this.$route.params.id) {
      this.editing = true;
    }
    utils.setStepStatus(this);
    utils.setStepRequireds(this);
  },
  async mounted() {
    if (this.editing) {
      await this.getCategory(this.$route.params.id, this.$route.params.integrationPlatform);
    }
  },
  mixins: [FormStepMixin, CategoryFormMixin],
  methods: {
    identicalObjects(obj1, obj2) {
      if (obj1 === null || obj2 === null) {
        return false;
      }

      const props1 = Object.getOwnPropertyNames(obj1);
      const props2 = Object.getOwnPropertyNames(obj2);

      if (props1.length !== props2.length) {
        return false;
      }

      for (let i = 0; i < props1.length; i += 1) {
        const val1 = props1[i];

        if (val1 !== '__ob__' && obj1[val1] !== obj2[val1]) {
          if (typeof obj1[val1] === 'object') {
            return this.identicalObjects(obj1[val1], obj2[val1]);
          }

          return false;
        }
      }

      return true;
    },
    async getCategory(id, integrationPlatform) {
      try {
        const resp = await getCategory(id, integrationPlatform);
        for (let i = 0; i < resp.data.stamps.length; i += 1) {
          // eslint-disable-next-line prefer-destructuring
          resp.data.stamps[i].initialExpirationDate = resp.data.stamps[i].initialExpirationDate.split('T')[0];
          // eslint-disable-next-line prefer-destructuring
          resp.data.stamps[i].finalExpirationDate = resp.data.stamps[i].finalExpirationDate.split('T')[0];
        }
        Object.keys(this.form).forEach((key) => {
          if (!resp.data[key]) {
            resp.data[key] = this.form[key];
          }
        });
        resp.data.installmentConfig.initialDate = moment(resp.data.installmentConfig.initialDate, 'YYYY-MM-DD').format('DDMMYYYY');
        resp.data.installmentConfig.finalDate = moment(resp.data.installmentConfig.finalDate, 'YYYY-MM-DD').format('DDMMYYYY');
        resp.data.installmentConfig.minValue = Number(resp.data.installmentConfig.minValue).toFixed(2);
        resp.data.installmentConfigKoerich.initialDate = moment(resp.data.installmentConfigKoerich.initialDate, 'YYYY-MM-DD').format('DDMMYYYY');
        resp.data.installmentConfigKoerich.finalDate = moment(resp.data.installmentConfigKoerich.finalDate, 'YYYY-MM-DD').format('DDMMYYYY');
        resp.data.installmentConfigKoerich.minValue = Number(resp.data.installmentConfigKoerich.minValue).toFixed(2);
        this.form = resp.data;
        this.savedForm = JSON.parse(JSON.stringify(resp.data));
      } catch (e) {
        notification('Erro ao recuperar a categoria', 'error');
      }
    },
    formSubbmit() {
      if (this.editing) {
        this.saveDialogAlert = true;
      } else {
        this.saveCategory();
      }
    },
    listToIds(list) {
      const ids = [];
      if (list) {
        for (let i = 0; i < list.length; i += 1) {
          ids.push(list[i].id);
        }
      }
      return ids;
    },
    addStamp(stamp) {
      if (stamp.id == null) {
        this.form.stamps.push({
          ...stamp,
          id: uuidv4(),
        });
      } else {
        for (let i = 0; i < this.form.stamps.length; i += 1) {
          if (stamp.id === this.form.stamps[i].id) {
            this.form.stamps[i] = stamp;
          }
        }
      }
    },
    deleteStamp(stamp) {
      if (stamp.id !== null) {
        for (let i = 0; i < this.form.stamps.length; i += 1) {
          if (stamp.id === this.form.stamps[i].id) {
            this.form.stamps.splice(i, 1);
          }
        }
      }
    },
    dialogConfirmation() {
      if (this.editing) {
        this.updateCategory();
      } else {
        this.saveCategory();
      }
    },
    async saveCategory() {
      if (!this.form.installmentConfig || this.form.installmentConfig.installments.length === 0) {
        this.form.installmentConfig = null;
      } else {
        this.form.installmentConfig.minValue = this.form?.installmentConfig?.minValue.replace('R$ ', '');
        this.form.installmentConfig.initialDate = moment(this.form?.installmentConfig?.initialDate, 'DDMMYYYY').format('YYYY-MM-DD');
        this.form.installmentConfig.finalDate = moment(this.form?.installmentConfig?.finalDate, 'DDMMYYYY').format('YYYY-MM-DD');
      }

      if (!this.form.installmentConfigKoerich || this.form.installmentConfigKoerich.installments.length === 0) {
        this.form.installmentConfigKoerich = null;
      } else {
        this.form.installmentConfigKoerich.minValue = this.form?.installmentConfigKoerich?.minValue.replace('R$ ', '');
        this.form.installmentConfigKoerich.initialDate = moment(this.form?.installmentConfigKoerich?.initialDate, 'DDMMYYYY').format('YYYY-MM-DD');
        this.form.installmentConfigKoerich.finalDate = moment(this.form?.installmentConfigKoerich?.finalDate, 'DDMMYYYY').format('YYYY-MM-DD');
      }
      this.saveDialogAlert = false;
      const data = await createCategory(this.form);
      if (data?.status === 201) {
        notification('Categoria salva com sucesso.', 'success');
        routerPush('/category');
      }
    },
    async updateCategory() {
      if (!this.form.installmentConfig || this.form.installmentConfig.installments.length === 0) {
        this.form.installmentConfig = null;
      } else {
        this.form.installmentConfig.minValue = this.form?.installmentConfig?.minValue.replace('R$ ', '');
        this.form.installmentConfig.initialDate = moment(this.form?.installmentConfig?.initialDate, 'DDMMYYYY').format('YYYY-MM-DD');
        this.form.installmentConfig.finalDate = moment(this.form?.installmentConfig?.finalDate, 'DDMMYYYY').format('YYYY-MM-DD');
      }

      if (!this.form.installmentConfigKoerich || this.form.installmentConfigKoerich.installments.length === 0) {
        this.form.installmentConfigKoerich = null;
      } else {
        this.form.installmentConfigKoerich.minValue = this.form.installmentConfigKoerich?.minValue.replace('R$ ', '');
        this.form.installmentConfigKoerich.initialDate =
        moment(this.form?.installmentConfigKoerich?.initialDate, 'DDMMYYYY').format('YYYY-MM-DD');
        this.form.installmentConfigKoerich.finalDate =
        moment(this.form?.installmentConfigKoerich?.finalDate, 'DDMMYYYY').format('YYYY-MM-DD');
      }
      this.saveDialogAlert = false;
      const data = await updateCategory(this.form);
      if (data?.status === 200) {
        notification('Categoria editada com sucesso.', 'success');
        routerPush('/category');
      }
    },
    dialogDenial() {
      this.saveDialogAlert = false;
    },
  },
};

</script>

<style>
.box-content {
  background-color: rgb(237, 237, 237);
  height: calc(100% - 80px);
  margin-top: 80px
}
</style>
