import moment from 'moment';

export default {
  data() {
    return {
      validateInstallmentConfig: true,
      validateInstallmentConfigKoerich: true,
      steps: {
        GeneralInformationForm: {
          step: 1,
          status: {},
          required: {
            id: true,
            name: true,
            description: true,
          },
          rules: {
            id: (value) => {
              if (!(value && value.length > 0)) {
                return 'Campo `Código` é Obrigatório.';
              }
              return !!value.match(/^[-_A-z0-9]*$/g) || 'O Padrão é Inválido.';
            },
            name: (value) => (value && value.length > 0) || 'Campo `Nome` é Obrigatório.',
            description: (value) => (value && value.length > 0) || 'Campo `Descrição` é Obrigatório.',
          },
        },
        CardsAndBilletForm: {
          step: 2,
          status: {},
          required: {
            installmentConfigInitialDate: false,
            installmentConfigFinalDate: false,
            installmentConfigMinValue: false,
            installmentConfigKoerichInitialDate: false,
            installmentConfigKoerichFinalDate: false,
            installmentConfigKoerichMinValue: false,
          },
          rules: {
            installmentConfigInitialDate: (value) => {
              this.setRequiredInstallmentConfigFields(value);
              const initial = moment(value?.installmentConfig?.initialDate, 'DDMMYYYY');
              const final = moment(value?.installmentConfig?.finalDate, 'DDMMYYYY');
              if (!!value && value?.installmentConfig?.maxInstallments > 0) {
                return !(value?.installmentConfig?.initialDate && value?.installmentConfig?.initialDate?.length > 0)
                  ? 'Campo `Data Inicial` é Obrigatório.'
                  : initial.isBefore(final) ||
                      !value?.installmentConfig?.finalDate ||
                      'Campo `Data Inicial` deve ser inferior `Data Final`.';
              }
              return true;
            },
            installmentConfigFinalDate: (value) => {
              this.setRequiredInstallmentConfigFields(value);
              const initial = moment(value?.installmentConfig?.initialDate, 'DDMMYYYY');
              const final = moment(value?.installmentConfig?.finalDate, 'DDMMYYYY');
              if (!!value && value?.installmentConfig?.maxInstallments > 0) {
                return !(value?.installmentConfig?.finalDate && value?.installmentConfig?.finalDate?.length > 0)
                  ? 'Campo `Data final` é Obrigatório.'
                  : initial.isBefore(final) ||
                      !value.installmentConfig.initialDate ||
                      'Campo `Data Final` deve ser superior `Data Inicial`.';
              }
              return true;
            },
            installmentConfigMinValue: (value) => {
              this.setRequiredInstallmentConfigFields(value);
              if (!!value && value?.installmentConfig?.maxInstallments > 0) {
                return !!value?.installmentConfig?.minValue || 'Campo `Valor mínimo parcelamento` é Obrigatório.';
              }
              return true;
            },
            installmentConfigKoerichInitialDate: (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              const initial = moment(value?.installmentConfig?.initialDate, 'DDMMYYYY');
              const final = moment(value?.installmentConfig?.finalDate, 'DDMMYYYY');
              if (!!value && (value?.installmentConfigKoerich?.maxInstallments > 0)) {
                return !(value?.installmentConfigKoerich?.initialDate && value?.installmentConfigKoerich?.initialDate?.length > 0) ? 'Campo `Data Inicial` é Obrigatório.' :
                (initial.isBefore(final) || !value?.installmentConfigKoerich?.finalDate) || 'Campo `Data Inicial` deve ser inferior `Data Final`.';
              }
              return true;
            },
            installmentConfigKoerichFinalDate: (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              const initial = moment(value?.installmentConfig?.initialDate, 'DDMMYYYY');
              const final = moment(value?.installmentConfig?.finalDate, 'DDMMYYYY');
              if (!!value && (value?.installmentConfigKoerich?.maxInstallments > 0)) {
                return !(value?.installmentConfigKoerich?.finalDate && value?.installmentConfigKoerich?.finalDate?.length > 0) ? 'Campo `Data final` é Obrigatório.' :
                (initial.isBefore(final) || !value?.installmentConfigKoerich?.initialDate) || 'Campo `Data Final` deve ser superior `Data Inicial`.';
              }
              return true;
            },
            installmentConfigKoerichMinValue: (value) => {
              this.setRequiredInstallmentConfigKoerichFields(value);
              if (!!value && value?.installmentConfigKoerich?.maxInstallments > 0) {
                return !!value?.installmentConfigKoerich?.minValue || 'Campo `Valor mínimo parcelamento` é Obrigatório.';
              }
              return true;
            },
          },
        },
        StampForm: {
          step: 3,
          status: {},
          required: {},
          rules: {},
        },
        IndexingForm: {
          step: 4,
          status: {},
          required: {},
          rules: {},
        },
      },
    };
  },
  methods: {
    setRequiredInstallmentConfigFields(value) {
      if (this.validateInstallmentConfig && !this.editing) {
        this.validateInstallmentConfig = false;
        this.steps.CardsAndBilletForm.required.installmentConfigInitialDate = true;
        this.steps.CardsAndBilletForm.required.installmentConfigFinalDate = true;
        this.steps.CardsAndBilletForm.required.installmentConfigMinValue = true;
      } else {
        this.validateInstallmentConfig = value && value.length > 0;
      }
    },
    setRequiredInstallmentConfigKoerichFields(value) {
      if (this.validateInstallmentConfigKoerich && !this.editing) {
        this.validateInstallmentConfigKoerich = false;
        this.steps.CardsAndBilletForm.required.installmentConfigKoerichInitialDate = true;
        this.steps.CardsAndBilletForm.required.installmentConfigKoerichFinalDate = true;
        this.steps.CardsAndBilletForm.required.installmentConfigKoerichMinValue = true;
      } else {
        this.validateInstallmentConfigKoerich = value && value.length > 0;
      }
    },
  },
};
