export const PrioritiesOptions = [
  { value: 1, text: '1 - Irrelevante' },
  { value: 2, text: '2 - Extremamente baixa' },
  { value: 3, text: '3 - Muito baixa' },
  { value: 4, text: '4 - Moderadamente Baixa' },
  { value: 5, text: '5 - Baixa' },
  { value: 6, text: '6 - Intermediária' },
  { value: 7, text: '7 - Moderadamente Alta' },
  { value: 8, text: '8 - Alta' },
  { value: 9, text: '9 - Muito Alta' },
  { value: 10, text: '10 - Extremamente Alta' }];

export default PrioritiesOptions;
